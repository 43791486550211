export const PUBLISHER_FEATURES_DATA = {
  features: [
    {
      copy: 'During setup you tell the Podcast Publisher how your podcast  is structured, where all the assets can be found, and how the naming conventions looks like. From there on it will automatically check for you that everything runs smoothly and all your files are where they belong.',
      image: '../images/assets.png',
      title: 'Peace of Mind',
    },
    {
      copy: 'The Podcast Publisher collects its data without tracking your listeners  — and still provides you with a lot of answers! For example: How many people listened to which episode? What about just the first week? How many people use the web player compared to direct download or feed subscription? Which podcast clients are popular?',
      image: '../images/analytics.png',
      title: 'Powerful Analytics',
    },
    {
      copy: 'When your listeners visit your website, the first thing they\'ll notice is our gorgeous web player. The customizable Podlove Webplayer comes bundled with the plugin and provides a fantastic experience to listen to your content. A big cover image, easy to use controls, tabs for transcripts, chapter marks, download options and so much more. ',
      image: '../images/publisher-player.png',
      title: 'Webpage with Player',
    },
    {
      copy: 'Custom templating allows you to access every piece of podcast data and enables you to build podcast pages for your listeners exactly the way you want. How about a page with your archive of all episodes? Or an overview with all the guests and the episodes they were in?  We provide free templates for you to use and adapt and our community offers a big collection of templates.',
      image: '../images/templating.png',
      title: 'Custom Templating',
    },
  ]
}