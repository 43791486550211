import PropTypes from 'prop-types'
import React from 'react'
import './waveAnimation.css'

const WaveAnimation = ({ isMirrored = false }) => {

  return (
    <div
      className={`
        waveWrapper
        waveAnimation
        ${isMirrored ? 'waveMirrored' : null}
      `}
    >
      <div className="waveWrapperInner bgTop">
        <div
          className="wave waveTop"
          style={{ backgroundImage: 'url("../images/wave-top.png")' }}
        ></div>
      </div>
      <div className="waveWrapperInner bgMiddle">
        <div
          className="wave waveMiddle"
          style={{ backgroundImage: 'url("../images/wave-middle.png")' }}
        ></div>
      </div>
      <div className="waveWrapperInner bgBottom">
        <div
          className="wave waveBottom"
          style={{ backgroundImage: 'url("../images/wave-bottom.png")' }}
        ></div>
      </div>
    </div>
  )
}

export default WaveAnimation

WaveAnimation.propTypes = {
  isMirrored: PropTypes.bool,
}
