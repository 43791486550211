import PropTypes from 'prop-types'
import React from 'react'
import { getRandomLightColor } from '../helpers/colors'

const DocsList = ({ code, copy, number, title }) => (
  <div
    key={number}
    className="
      items-start
      md:flex
      py-6
    "
  >
    <p
      className="
        font-black
        leading-none
        mr-10
        text-6xl
        text-blue-200
      "
    >{number}</p>
    <div>
      <h3
        className="
          font-bold
          pb-4
          text-xl
        "
      >{title}</h3>
      <p>{copy}</p>
      { code &&
        <p
          className={`
            bg-${getRandomLightColor()}
            break-all
            font-mono
            my-4
            p-4
            rounded-lg
          `}
        >{code.split('\n').map(str => <p key={str}>{str}</p>)}</p>
      }
    </div>
  </div>
)

DocsList.propTypes = {
  code: PropTypes.string,
  copy: PropTypes.string,
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default DocsList