export const PUBLISHER_INSTALLATION_DATA_FTP = {
  data: [
    {
      copy: 'If downloaded as a zip archive, extract the Plugin folder to your desktop.',
      title: 'Download the Podlove Publisher Plugin to your desktop.',
    },
    {
      copy: 'With your FTP program, upload the Plugin folder to the wp-content/plugins folder in your WordPress directory online.',
      title: 'Upload the plugin',
    },
    {
      copy: 'Click Activate Plugin to activate it.',
      title: 'Go to Plugins screen and find the newly uploaded Plugin in the list.',
    },
  ]
}

export const PUBLISHER_INSTALLATION_DATA_WORDPRESS = {
  data: [
    {
      copy: 'If downloaded as a zip archive, extract the Plugin folder to your desktop.',
      title: 'Download the Podlove Publisher Plugin to your desktop.',
    },
    {
      copy: 'With your FTP program, upload the Plugin folder to the wp-content/plugins folder in your WordPress directory online.',
      title: 'Upload the plugin',
    },
    {
      copy: 'Click Activate Plugin to activate it.',
      title: 'Go to Plugins screen and find the newly uploaded Plugin in the list.',
    },
  ]
}