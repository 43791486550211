import React from 'react'

import { getRandomColor } from '../helpers/colors'
import ArticleHeader from '../elements/articleHeader'
import { PUBLISHER_BENEFITS_DATA } from '../content/publisherBenefits'

const PublisherBenefits = () => (
  <div
    id="features"
    className="
      bg-blue-800
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-4
      md:px-12
      py-20
      w-screen
      text-white
      text-center
      tracking-wide
    "
  >
    <ArticleHeader
      subtitle="The Podlove Podcast Publisher follows the podcasters’ perspective and implements a variety of features and workflows that have been rather difficult to achieve with other publishing solutions. The plugin makes the process easier by letting you set a few conventions at the beginning and making publishing and maintaining podcast feeds a snap and highly reliable from there on."
      title="A Solid Foundation"
    />
    <div
      className="
        flex
        flex-wrap
        px-12
        max-w-6xl
      "
    >
      { PUBLISHER_BENEFITS_DATA.benefits.map(item =>
        <div
          key={JSON.stringify(item)}
          className="
            text-left
            px-5
            py-12
            w-full
            sm:w-1/2
            lg:w-1/3
          "
        >
          <div
            className={`
              bg-${getRandomColor()}
              h-10
              items-center
              justify-center
              flex
              my-4
              rounded-md
              text-white
              w-10
            `}
          >
            <img src={item.icon} />
          </div>
          <h3
            className="
              font-bold
              pb-4
              text-xl
            "
          >{item.title}</h3>
          <p>{item.copy}</p>
        </div>
      )}
    </div>
  </div>
)

export default PublisherBenefits