import React from 'react'

import { PUBLISHER_MORE_INFORMATION } from '../content/publisherMoreInformation'

import ArticleHeader from '../elements/articleHeader'
import InfoCard from '../elements/infoCard'
import { getRandomColorName } from '../helpers/colors'

const PublisherMoreInformation = () => (
  <div
    id="support"
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
  >
    <ArticleHeader
      subtitle="If you are interested in using or integrating the Podlove Podcast Publisher, check here for more information:"
      title="More Information about Podlove Publisher"
    />
    <div
      className="
        md:flex
        items-baseline
        md:px-12
        md:space-x-4
        space-y-8
        md:space-y-4
        max-w-screen-xl
      "
    >
      { PUBLISHER_MORE_INFORMATION.information.map(item =>
        <InfoCard
          color={getRandomColorName()}
          copy={item.copy}
          ctaLabel={item.ctaLabel}
          ctaPath={item.ctaPath}
          icon={item.icon}
          key={JSON.stringify(item)}
          title={item.title}
        />
      )}
    </div>
  </div>
)

export default PublisherMoreInformation