import PropTypes from 'prop-types'
import React from 'react'

import { getRandomColor, getRandomDarkColor } from '../helpers/colors'
import WaveAnimation from '../elements/waveAnimation'
import Button from '../elements/button'
import Animation from '../elements/animation'

const Hero = ({ benefits, blank = false, copy, cta, ctaLink, ctaColor, headline, showIllustration = true, showColorAnimation = false }) => (
  <div
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      max-w-8xl
      ml-auto
      mr-auto
      px-4
      md:px-12
      pb-36
      md:pb-72
      pt-40
      md:pt-80
      relative
      space-y-8
      text-blue-800
      text-center
      tracking-wide
    "
  >
    { showColorAnimation &&
      <Animation />
    }
    <h1
      className="
        font-black
        leading-snug
        max-w-3xl
        text-4xl
        md:text-5xl
        z-10
      "
    >{headline}</h1>
    <p
      className="
        max-w-3xl
        text-lg
        md:text-xl
        z-10
      "
    >{copy}</p>
    { cta && !blank &&
      <Button
        bgColor={ctaColor ? ctaColor : getRandomColor()}
        bgColorHover={ctaColor ? getRandomColor() : getRandomDarkColor()}
        label={cta}
        to={ctaLink}
      />
    }
    { cta && blank &&
      <a
        className={`
          bg-${ctaColor ? ctaColor : getRandomColor()}
          font-medium
          pb-2
          pl-5
          pr-5
          pt-2
          rounded-md
          text-gray-100
          hover:bg-${ctaColor ? getRandomColor() : getRandomDarkColor()}
          transition
          duration-500
          ease-in-out
        `}
        href={ctaLink}
        target="_blank"
        rel="noreferrer"
      >{cta}</a>
    }
    { benefits && benefits.length > 0 &&
      <div
        className="
          flex
          flex-wrap
          px-12
          max-w-6xl
          z-10
        "
      >
        { benefits.map((item) => 
          <div
            key={JSON.stringify(item)}
            className="
              text-left
              px-4
              py-12
              w-full
              sm:w-1/2
              lg:w-1/3
            "
          >
            <div
              className={`
                bg-${getRandomColor()}
                h-10
                items-center
                justify-center
                flex
                my-4
                rounded-md
                text-white
                w-10
              `}
            >
              <img src={item.icon} />
            </div>
            <h3
              className="
                font-bold
                pb-4
                text-xl
              "
            >{item.title}</h3>
            <p>{item.copy}</p>
          </div>
        )}
      </div>
    }
    { showIllustration &&
      <WaveAnimation />
    }
  </div>
)

export default Hero

Hero.propTypes = {
  benefits: PropTypes.array,
  blank: PropTypes.bool,
  copy: PropTypes.string,
  cta: PropTypes.string,
  ctaColor: PropTypes.string,
  ctaLink: PropTypes.string,
  headline: PropTypes.string,
  showIllustration: PropTypes.bool,
  showColorAnimation: PropTypes.bool,
}
