import PropTypes from 'prop-types'
import React from 'react'

const TabsContainer = ({
  firstTabActive,
  children,
  onPressLeft,
  onPressRight,
  titleLeft,
  titleRight,
}) => (
  <div
    className="
      md:flex
      md:flex-col
      items-center
    "
  >
    { titleLeft && titleRight &&
      <div
        className="
          flex
          z-10
        "
      >
        <button
          className={`
            ${firstTabActive ? 'bg-gray-200' : 'bg-blue-200'}
            border-b-2
            border-l-2
            border-t-2
            border-blue-200
            font-bold
            px-4
            py-2
            rounded
            rounded-r-none
            text-sm
            uppercase
          `}
          onClick={onPressLeft}
        >{titleLeft}</button>
        <button
          className={`
            ${!firstTabActive ? 'bg-gray-200' : 'bg-blue-200'}
            border-2
            border-blue-200
            px-4
            py-2
            rounded
            rounded-l-none
            text-blue-400
            text-sm
            uppercase
          `}
          onClick={onPressRight}
        >{titleRight}</button>
      </div>
    }
    <div
      className="
        border-2
        border-blue-200
        rounded-lg
        -mt-5
        max-w-6xl
        p-12
        text-left
      "
    >
      {children}
    </div>
  </div>
)

TabsContainer.propTypes = {
  firstTabActive: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onPressLeft: PropTypes.func.isRequired,
  onPressRight: PropTypes.func.isRequired,
  titleLeft: PropTypes.string,
  titleRight: PropTypes.string,
}

export default TabsContainer