import PropTypes from 'prop-types'
import React from 'react'

import Headline from '../elements/headline'

const ArticleHeader = ({ subtitle, title }) => (
  <>
    <Headline level={2}>{title}</Headline>
    { subtitle ? <Headline level={3}>{subtitle}</Headline> : null }
  </>
)

ArticleHeader.propTypes = {
  color: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default ArticleHeader