import PropTypes from 'prop-types'
import React from 'react'

import './animation.css'
import { getRandomColor } from '../helpers/colors'

const Animation = ({
  numberOfElements = 6,
}) => {

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <div
      className="
        wrapper
      "
    >
      { [...Array(numberOfElements)].map(i =>
        <div
          key={`${i}_${Math.random()}`}
          className={`
            element
            bg-${getRandomColor()}
          `}
          style={{
            width: randomIntFromInterval(150, 600),
            left: -600,
            top: `${randomIntFromInterval(12, 72)}%`,
            animationDelay: `${randomIntFromInterval(0, numberOfElements * 2)}s`,
            animationDuration: `${randomIntFromInterval(100, 140)}s`,
          }}
        ></div>
      )}
    </div>
  )
}

export default Animation

Animation.propTypes = {
  numberOfElements: PropTypes.number,
}
