import React from 'react'

import ArticleHeader from '../elements/articleHeader'
import { PUBLISHER_FEATURES_DATA } from '../content/publisherFeatures'
import Animation from '../elements/animation'

const PublisherFeatures = () => (
  <div
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      text-center
      tracking-wide
      relative
    "
    id="benefits"
  >
    <ArticleHeader
      subtitle="Our main goal with the Publisher is to hand you all the tools to host and publish your podcast in the best way possible with all the features the podcasting environment has created over the years: Shownotes, chapter marks, transcripts, contributors … and so much more."
      title="Main features"
    />
    <div
      className="
        flex
        flex-wrap
        md:px-12
        max-w-6xl
      "
    >
      <Animation numberOfElements={24} />
      { PUBLISHER_FEATURES_DATA.features.map((item, index) =>
        <div
          key={JSON.stringify(item)}
          className={`
            flex
            flex-col
            md:flex-row${index%2 !== 0 ? '-reverse' : ''}
            py-12
            text-left
            z-10
          `}
        >
          <div
            className="
              bg-blue-200
              h-72
              rounded-lg
              md:w-1/2
              overflow-hidden
              bg-cover
              bg-center
              shadow-md
            "
            style={{
              backgroundImage: `url(${item.image})`,
            }}
          ></div>
          <div
            className={`
              ${index%2 !== 0 ? 'text-right' : null}
              flex
              flex-col
              justify-center
              mt-8
              md:mt-0
              px-8
              md:w-1/2
            `}
          >
            <h3
              className="
                font-bold
                pb-4
                text-xl
              "
            >{item.title}</h3>
            <p>{item.copy}</p>
          </div>
        </div>
      )}
    </div>
  </div>
)

export default PublisherFeatures