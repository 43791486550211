import React, { useState } from 'react'
import { PUBLISHER_INSTALLATION_DATA_WORDPRESS, PUBLISHER_INSTALLATION_DATA_FTP } from '../content/publisherInstallation'
import ArticleHeader from '../elements/articleHeader'
import DocsList from '../elements/docsList'
import Button from '../elements/button'
import TabsContainer from '../elements/tabsContainer'

const PublisherInstallation = () => {
  const [firstTabActive, setFirstTabActive] = useState(true)
  return (
    <div
      id="installation"
      className="
        bg-gray-200
        flex
        flex-col
        flex-wrap
        font-sans
        items-center
        justify-center
        px-12
        py-20
        w-screen
        space-y-8
        text-blue-800
        text-center
        tracking-wide
        z-10
      "
    >
      <ArticleHeader
        subtitle="Download the Podlove Publisher WordPress plugin here and follow the instructions."
        title="Easy to install"
      />
      <Button
        label="Download Podlove Podcast Publisher"
        to="https://wordpress.org/plugins/podlove-podcasting-plugin-for-wordpress"
      />
      <div
        className="
          flex
          flex-wrap
          px-12
          max-w-6xl
        "
      ></div>
      <TabsContainer
        firstTabActive={firstTabActive}
        onPressLeft={() => setFirstTabActive(true)}
        onPressRight={() => setFirstTabActive(false)}
        titleLeft="FTP (manuell)"
        titleRight="WordPress Installer"
      >
        { firstTabActive && PUBLISHER_INSTALLATION_DATA_FTP.data.map((item, index) =>
          <DocsList
            code={item.code}
            copy={item.copy}
            key={index}
            number={index + 1}
            title={item.title}
          />
        )}
        { !firstTabActive && PUBLISHER_INSTALLATION_DATA_WORDPRESS.data.map((item, index) =>
          <DocsList
            code={item.code}
            copy={item.copy}
            key={index}
            number={index + 1}
            title={item.title}
          />
        )}
      </TabsContainer>
    </div>
  )
}

export default PublisherInstallation