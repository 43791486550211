const colorNames = [
  'deeplilac',
  'purplemajesty',
  'marguerite',
  'keppel',
  'fern',
  'cucumber',
  'copper',
  'roman',
  'deepblush',
  'freespeechblue',
  'pictonblue',
  'turquoise',
]

export const getRandomDarkColor = () => {
  const hues = ['600', '700', '800', '900']
  return `${colorNames[Math.floor(Math.random() * colorNames.length)]}-${hues[Math.floor(Math.random() * hues.length)]}`
}

export const getRandomColor = () => {
  const hues = ['400', '500', '600']
  return `${colorNames[Math.floor(Math.random() * colorNames.length)]}-${hues[Math.floor(Math.random() * hues.length)]}`
}

export const getRandomColorName = () => {
  return colorNames[Math.floor(Math.random() * colorNames.length)]
}

export const getRandomLightColor = () => {
  const hues = ['100', '200', '300']
  return `${colorNames[Math.floor(Math.random() * colorNames.length)]}-${hues[Math.floor(Math.random() * hues.length)]}`
}