import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import PublisherBenefits from '../components/publisherBenefits'
import PublisherFeatures from '../components/publisherFeatures'
import PublisherInstallation from '../components/publisherInstallation'
import PublisherMoreInformation from '../components/publisherMoreInformation'

const NAV_ITEMS = [
  {
    name: 'Features',
    path: '#features',
  },
  {
    name: 'Benefits',
    path: '#benefits',
  },
  {
    name: 'Installation',
    path: '#installation',
  },
]

const IndexPage = () => (
  <Layout
    subHeaderNavItems={NAV_ITEMS}
    subHeaderNavTitle={'Podlove Publisher'}
    subHeaderColor={'podlove-blue-500'}
  >
    <Seo title="Home" />
    <Hero
      copy="Our Publisher really is a nifty tool for all podcasters built in the most popular blogging platform out there. We use WordPress as the basis and built upon it a software that follows your publication workflow from start to finish. This helps you save time, worry less, and provides a lot of modern day features for your listeners."
      cta="Install Publisher"
      ctaLink="https://de.wordpress.org/plugins/podlove-podcasting-plugin-for-wordpress/"
      ctaColor={'podlove-blue-500'}
      blank
      headline="Podcast Publishing for WordPress"
    />
    <PublisherBenefits />
    <PublisherFeatures />
    <PublisherInstallation />
    <PublisherMoreInformation />
  </Layout>
)

export default IndexPage
