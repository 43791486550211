import PropTypes from 'prop-types'
import React from 'react'

import Button from './button'

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const InfoCard = ({ color, copy, ctaLabel, ctaPath, icon, title }) => (
  <div
    className="
      bg-blue-200
      text-left
      px-5
      pt-12
      py-8
      relative
      max-w-xs
    "
  >
    <div
      className={`
        spinning-element
        absolute
        bg-${color}-100
        h-80
        left-0
        -mt-12
        -ml-12
        rounded-full
        top-0
        w-80
        -z-10
      `}
      style={{
        transformOrigin: `${randomIntFromInterval(35, 65)}% ${randomIntFromInterval(35, 65)}%`,
        animationDuration: `${randomIntFromInterval(10, 20)}s`,
      }}
    ></div>
    <div
      className={`
        absolute
        bg-${color}-500
        h-10
        left-0
        -mt-5
        ml-5
        rounded-full
        top-0
        text-${color}-800
        w-10
      `}
    >
      { icon && <img src={icon} /> }
    </div>
    <h3
      className="
        font-bold
        pb-4
        text-xl
      "
    >{title}</h3>
    <p className="mb-4">{copy}</p>
    { ctaLabel && ctaPath &&
      <Button
        bgColor={`${color}-500`}
        bgColorHover={`${color}-600`}
        label={ctaLabel}
        to={ctaPath}
      />
    }
  </div>
)

InfoCard.propTypes = {
  color: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string,
  ctaPath: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default InfoCard