export const PUBLISHER_MORE_INFORMATION = {
  information: [
    {
      copy: 'Information about the integration into your site, the extension possibilities of the Podlove Publisher or the use in WordPress.',
      ctaLabel: 'Visit WordPress.com',
      ctaPath: 'https://wordpress.org/plugins/podlove-podcasting-plugin-for-wordpress/',
      icon: '../images/icons/wordpress--white.png',
      title: 'Documentation',
    },
    {
      ctaLabel: 'Visit the Podlove Community',
      ctaPath: 'https://community.podlove.org/',
      copy: 'Problems with the installation, configuration or use of the Podlove Publisher? Get in touch with us and get help from our community!',
      icon: '../images/icons/community--white.png',
      title: 'Support',
    },
    {
      ctaLabel: 'Publisher on Github',
      ctaPath: 'https://github.com/podlove/podlove-publisher',
      copy: 'Do you want to take a look into the machinery or help us with development? Then check out the repository.',
      icon: '../images/icons/github--white.png',
      title: 'Github Repository',
    },
  ]
}